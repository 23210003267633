import * as React from "react";
import { Text, Box, VStack, Flex } from "@chakra-ui/react";
import { ChevronRightIcon, ChevronLeftIcon } from "@chakra-ui/icons";
import { motion } from "framer-motion";

const ARRAY_LENGTH = 100;

export type Types = "words" | "numbers";

function SlotMachine({
  type = "words",
  entries = ["😩", "😍", "😂"],
  rowHeight = 28,
  range = [0, 1],
}: {
  type?: Types;
  entries?: string[];
  rowHeight?: number;
  range?: [number, number];
}): JSX.Element {
  let arr = [];
  if (type === "words") {
    for (let i = 0; i < ARRAY_LENGTH; i++) {
      let randomEntry = Math.round(Math.random() * (entries.length - 1));
      arr.push(entries[randomEntry]);
    }
  } else {
    for (let i = 0; i < ARRAY_LENGTH; i++) {
      let randomEntry = Math.round(
        Math.random() * (range[1] - range[0]) + range[0]
      );
      arr.push(randomEntry);
    }
  }
  return (
    <Box h={`${rowHeight * 10}px`} overflowY="hidden">
      <Flex>
        <Box textAlign="right">
          <ChevronRightIcon
            boxSize={`${rowHeight}px`}
            marginTop={`${rowHeight * 4.4}px`}
          />
        </Box>
        <Box flex={1}>
          <motion.div
            animate={{
              transform: `translate(0,-${
                rowHeight * 2 * 90 - rowHeight / 2
              }px)`,
            }}
            transition={{ ease: "easeOut", duration: 10 }}
          >
            <VStack spacing={`${rowHeight}px`}>
              {arr.map((entry, i) => (
                <motion.div
                  key={i}
                  style={{opacity: 0.6 }}
                  animate={i === 92 ? { scale: 1.5, opacity: 1 } : {}}
                  transition={{ delay: 10, duration: 1 }}
                >
                  <Text
                    marginLeft="10px"
                    marginRight="10px"
                    height={rowHeight + "px"}
                    isTruncated
                    maxW={"20rem"}
                  >
                    {entry}
                  </Text>
                </motion.div>
              ))}
            </VStack>
          </motion.div>
        </Box>
        <Box textAlign="left">
          <ChevronLeftIcon
            boxSize={`${rowHeight}px`}
            marginTop={`${rowHeight * 4.4}px`}
          />
        </Box>
      </Flex>
    </Box>
  );
}

export default SlotMachine;
