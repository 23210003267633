import React, { useState } from "react";
import {
  ChakraProvider,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  VStack,
  Flex,
  Input,
  Button,
  IconButton,
  theme,
  ModalCloseButton,
  Heading,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Text,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from "@chakra-ui/react";
import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
import { ColorModeSwitcher } from "./ColorModeSwitcher";
import SlotMachine, { Types } from "./components/SlotMachine";

export const App = () => {
  const [type, setType] = useState("words");
  const [inputs, setInputs] = useState([""]);
  const [range, setRange] = useState([0, 1]);
  const [modalOpen, setModalOpen] = useState(false);

  const addInput = () => {
    setInputs([...inputs, ""]);
  };

  const loopSearch = (i: number) => {
    setTimeout(() => {
      if (document.getElementById(`input-${i + 1}`)) {
        document.getElementById(`input-${i + 1}`)?.focus();
      } else loopSearch(i);
    }, 10);
  };

  return (
    <ChakraProvider theme={theme}>
      <ColorModeSwitcher position="absolute" top="10px" right="10px" />
      <VStack spacing={8} paddingTop="12%" paddingBottom="2rem">
        <Heading>Tilfældig</Heading>
        <Tabs
          isFitted
          variant="enclosed"
          minW="20rem"
          onChange={(e) => setType(e === 0 ? "words" : "numbers")}
        >
          <TabList mb="1em">
            <Tab>Ord</Tab>
            <Tab>Tal</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <VStack spacing={8}>
                {inputs.map((s, i) => (
                  <Flex key={i} w={["95%", "30rem"]}>
                    <Input
                      defaultValue={s}
                      id={`input-${i}`}
                      onChange={(e) => {
                        let newInputs = inputs;
                        newInputs[i] = e.target.value;
                        setInputs(newInputs);
                      }}
                      onKeyDown={(e) => {
                        if (e.key !== "Enter") return;
                        if (i === inputs.length - 1) addInput();
                        loopSearch(i);
                      }}
                      borderRightRadius={i === 0 ? "base" : 0}
                      placeholder={`Indtast ${i + 1}. mulighed`}
                    />
                    {i !== 0 && (
                      <IconButton
                        aria-label="Fjern"
                        variant="outline"
                        icon={<DeleteIcon />}
                        borderLeftRadius={0}
                        marginLeft="-1px"
                        onClick={() => {
                          let arr = [...inputs];
                          arr.splice(i, 1);
                          setInputs([]);
                          setTimeout(() => {
                            setInputs(arr);
                          }, 1); //Dumbass hack as useState has no callback
                        }}
                      />
                    )}
                  </Flex>
                ))}
                <Button
                  variant="outline"
                  w="100%"
                  leftIcon={<AddIcon />}
                  onClick={() => addInput()}
                >
                  Tilføj mulighed
                </Button>
              </VStack>
            </TabPanel>
            <TabPanel>
              <Flex justify="space-between" align="center" w={["95%", "30rem"]}>
                <NumberInput
                  onChange={(e) => {
                    let newRange = [...range];
                    newRange[0] = parseInt(e);
                    setRange(newRange);
                  }}
                  marginRight={1}
                >
                  <NumberInputField placeholder="Fra" />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
                <Text>-</Text>
                <NumberInput
                  onChange={(e) => {
                    let newRange = [...range];
                    newRange[1] = parseInt(e);
                    setRange(newRange);
                  }}
                  marginLeft={1}
                >
                  <NumberInputField placeholder="Til" />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
              </Flex>
            </TabPanel>
          </TabPanels>
        </Tabs>
        <Button size="lg" onClick={() => setModalOpen(true)}>
          Træk
        </Button>
        {/*<a
          href="https://www.linkedin.com/in/thomasgrastein/"
          target="_blank"
          rel="noreferrer"
        >
          <Text fontSize="smaller" opacity={0.5}>@thomasgrastein</Text>
        </a>*/}
      </VStack>
      <Modal isOpen={modalOpen} onClose={() => setModalOpen(false)} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody w={["100%", "auto"]}>
            <SlotMachine
              entries={inputs}
              range={range as [number, number]}
              type={type as Types}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </ChakraProvider>
  );
};
